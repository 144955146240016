<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Startup" />
		</div>
		<div>
			<HeroBox title="Startup" subtitle="Startup Process" color="is-success" />
		</div>
		<div v-if="showOutstandingList">
			<OutstandingValues />
		</div>
	</layout-default-new>
</template>

<script>
import useSearchBoxState from '@/_srcv2/views/_shared/search_base/components/search-box/useSearchBoxState.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import OutstandingValues from '@/_srcv2/views/pages/startup/layout/OutstandingValues.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import { onMounted } from '@vue/composition-api'
import useStartupState from '@/_srcv2/views/pages/startup/layout/useStartupState.js'

export default {
	name: 'StartupNewYear',
	components: {
		SubNavbar,
		OutstandingValues,
		HeroBox,
	},
	setup() {
		const { showOutstandingList } = useStartupState()
		onMounted(() => {
			showSearchBox.value = true
		})

		const { showSearchBox, searchFinished } = useSearchBoxState()
		return {
			showSearchBox,
			searchFinished,
			showOutstandingList,
		}
	},
}
</script>

<style scoped></style>
