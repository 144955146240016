<!-- @format -->

<template>
	<transition name="modal-fade">
		<div>
			<div class="modal-backdrop">
				<div
					class="modal"
					role="dialog"
					aria-labelledby="modalTitle"
					aria-describedby="modalDescription"
				>
					<header class="modal-header" id="modalTitle">
						<slot name="header"></slot>
					</header>

					<div class="modal-body" id="modalDescription">
						<b-button
							type="button"
							class="is-danger width-200"
							@click="onCloseModal"
							aria-label="Close modal"
							style="width: 250px"
						>
							X Close
						</b-button>
						<SetOutstandingValues />
						<slot name="body"></slot>
					</div>
					<footer class="modal-footer">
						<slot name="footer"></slot>
					</footer>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import SetOutstandingValues from '@/_srcv2/views/pages/startup/components/set-outstanding-values/SetOutstandingValues.vue'
export default {
	name: 'ModalStartupFrame',
	components: { SetOutstandingValues },
	props: {
		onClose: { type: Function, required: false },
	},
	setup(props) {
		const onPropsClose = props.onClose ? props.onClose : () => {}
		const onCloseModal = () => {
			onPropsClose()
		}
		// --------------------------------------------------------------------------------
		// ---------------------------------------------------------------------------
		return {
			onCloseModal,
		}
	},
}
</script>

<style scoped>
.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000000001;
}
.modal {
	background-color: black;
	box-shadow: 2px 2px 20px 1px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	max-width: 780px;
	max-height: 100%;
	height: 900px;
	margin: auto;
	padding: 20px;
	opacity: 1;
}
.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}
.modal-header {
	position: relative;
	color: #4aae9b;
	justify-content: space-between;
}
.modal-footer {
	flex-direction: column;
	justify-content: flex-end;
}
.modal-body {
	position: relative;
	padding: 20px 10px;
}
.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}
</style>
