import { reactive, toRefs } from '@vue/composition-api'

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	companyId: '',
	stockId: '',
	stockName: '',
	stockUnit: '',
	stockAmount: '',
	outstandingBalance: '',
	outstandingCost: '',
})
export default function useSelectedCompanyState() {
	return { ...toRefs(state) }
}
