<!-- @format -->

<template>
	<div>
		<div>
			<form id="formSetOutstandingValues">
				<vue-form-generator
					@validated="onFormValidated"
					:schema="schemaOutstandingValues"
					:model="modelOutstandingValues"
					:options="formOptionsOutstandingValues"
				>
				</vue-form-generator>
			</form>
			<b-button
				:disabled="!!toggleAddButton"
				class="is-primary"
				type="submit"
				@click="onSubmit"
				>Save Outstanding Values
			</b-button>
		</div>
	</div>
</template>

<script>
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import { reactive, ref } from '@vue/composition-api'
import useSelectedStockItemState from '@/_srcv2/views/_shared/states/useSelectedStockItemState'
import SetOutstandingBalanceMutation from '@/_srcv2/graphql/stock/mutations/setOutstandingBalance.mutation.gql'
import { useMutation } from '@vue/apollo-composable'
import useStartupState from '@/_srcv2/views/pages/startup/layout/useStartupState.js'

export default {
	name: 'SetOutstandingValues',
	setup() {
		const toggleAddButton = ref(true)
		const onFormValidated = (isValid, errors) => {
			if (isValid === true) {
				toggleAddButton.value = false
			} else if (errors.length !== 0) {
				toggleAddButton.value = true
			}
		}
		const convertToInt100 = (cleaveStr) => {
			let getRidOfSpace = cleaveStr.replace(/\s/g, '')
			// console.log('getRidOfSpace', getRidOfSpace)
			let getRidOfDot = getRidOfSpace.replace(',', '.')
			// console.log('getRidOfDot', getRidOfDot)
			// console.log('to database', parseFloat(getRidOfDot) * 100)
			return Math.round(parseFloat(getRidOfDot) * 100)
		}
		// Add Outstanding Values
		const { mutate: addValues, onDone } = useMutation(
			SetOutstandingBalanceMutation,
			() => ({
				variables: {
					company_id: modelOutstandingValues.ourCompany,
					stock_id: modelOutstandingValues.stockIdField,
					outstanding_balance: modelOutstandingValues.outstandingBalanceField,
					outstanding_cost: convertToInt100(
						modelOutstandingValues.outstandingCostField,
					),
				},
			}),
		)
		// todo ---------------------------------------------------------------------
		const { isModalVisible, showOutstandingList } = useStartupState()
		const onSubmit = () => {
			addValues()
		}
		// todo -------------------------------------------------------------------------
		onDone(() => {
			console.log('onDone')
			isModalVisible.value = false
			showOutstandingList.value = false
			setTimeout(() => {
				showOutstandingList.value = true
				console.log('after five seconds')
			}, 500)
		})
		const { companyId, stockId, stockName, stockUnit } =
			useSelectedStockItemState()
		const modelOutstandingValues = reactive({
			ourCompany: companyId.value,
			stockIdField: stockId.value,
			stockNameField: stockName.value,
			stockUnitField: stockUnit.value,
			// stockAmountField: stockAmount.value,
			outstandingBalanceField: '',
			outstandingCostField: '',
		})
		const schemaOutstandingValues = reactive({
			groups: [
				{
					legend: 'Stock Item Outstanding Values',
					id: 'headers',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label: 'Our Company',
							model: 'ourCompany',
							id: 'company_name',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock Item ID',
							model: 'stockIdField',
							id: 'stock_id',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock Item Name',
							model: 'stockNameField',
							id: 'stock_name',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						{
							type: 'input',
							inputType: 'text',
							label: 'Stock Unit',
							model: 'stockUnitField',
							id: 'stock_unit',
							readonly: true,
							featured: true,
							disabled: true,
							required: true,
						},
						// {
						// 	type: 'input',
						// 	inputType: 'text',
						// 	label: 'Stock Amount',
						// 	model: 'stockAmountField',
						// 	id: 'stock_amount',
						// 	readonly: true,
						// 	featured: true,
						// 	disabled: true,
						// 	required: true,
						// },
						{
							type: 'input',
							inputType: 'number',
							label: 'Outstanding Balance',
							model: 'outstandingBalanceField',
							placeholder: 'Please enter outstanding balance',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: ['integer', 'required'],
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Outstanding Balance field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'cleave',
							label: 'Outstanding Cost',
							model: 'outstandingCostField',
							placeholder: 'Please enter outstanding cost',
							featured: true,
							required: true,
							validator: ['required'],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0) {
									console.log(
										'Validation error in Outstanding Cost field! Errors:',
										errors,
									)
									model.outstandingCostField = ''
								} else {
									console.log(
										'outstandingCostField',
										model.outstandingCostField,
									)
									console.log(
										'outstandingCostField*100',
										convertToInt100(model.outstandingCostField),
									)
								}
							},
						},
					],
				},
			],
		})
		const formOptionsOutstandingValues = reactive({
			validateAfterLoad: true,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'outstandingvalues',
		})
		return {
			onFormValidated,
			toggleAddButton,
			onSubmit,
			modelOutstandingValues,
			schemaOutstandingValues,
			formOptionsOutstandingValues,
		}
	},
}
</script>

<style scoped></style>
