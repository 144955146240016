import { reactive, toRefs } from '@vue/composition-api'

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not share the state
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	// Search Box state
	showSearchBox: false,
	searchString: '',
	searchFinished: false,
	// -----------------------------
	// Route after search is finished
	routeToPage: 'CompaniesIdentityDetails',
	// -----------------------------
	showEditButton: true,
})
export default function useSearchBoxState() {
	return { ...toRefs(state) }
}
